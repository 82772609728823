import Cookies from "./modules/cookies"; // Cookie Consent
import MobileNav from "./modules/mobileNav"; // Mobile Navigation
import "./modules/menuSwitch"; // Switch Navigation Menus
import ShopFilter from "./modules/shopFilter"; // Simple filter with shop
import ProductFilter from "./modules/productFilter"; // Simple filter without shop
import ScrollSpy from "./modules/scrollspy"; // Scrollspy
import HomeIntro from "./modules/homeIntro"; // Homepage Intro
import TestimonialSwiper from "./modules/testimonialsSwiper"; // Homepage Testimonials
import TechnologySwiper from "./modules/technologiesSwiper"; // Homepage Technologies
import ImageZoom from "./modules/imageZoom"; // Zoom Images via fancybox 4
import Hexagon from "./modules/hexagon"; // Zoom Images via fancybox 4
import Slider from "./modules/slider"; // Default image slider
import AnchorButton from "./modules/anchorButton"; // Default image slider
import EventCountdown from "./modules/eventCountdown"; // Countdown on card
import Accordion from "./modules/accordion"; // Accordion
import Modal from "./modules/modal"; // Modals
import ContactForm from "./modules/contactForm"; // Contact forms via ajax
import Truncate from "./modules/truncate"; // Truncate text
import Headline from "./modules/headline"; // Minfiy headline on scroll down
import EventsList from "./modules/eventsList"; // Minfiy headline on scroll down
import TemplateSidebar from "./modules/templateSidebar"; // Minfiy headline in template-sidebar
import ProductTabCardSlider from "./modules/product-tab-card__slider"; // Minfiy headline in template-sidebar
import ShopSlider from "./modules/shopslider"; // Minfiy headline in template-sidebar
import ManufacturersFilter from "./modules/manufacturersFilter"; // Filter for manufacturers menu
import UsecasesFilter from "./modules/usecasesFilter"; // Filter for usecases
import AnchorTeaser from "./modules/anchorTeaser"; // Filter for manufacturers menu
// import TestForm from './modules/testForm'; // HubSpot Test form
// import PromotionBanner from './modules/promotionBanner'; // HubSpot Test form
import Newsletter from "./modules/newsletter"; // HubSpot Test form
import Download from "./modules/download"; // Parallax scrolling for download-component
import Popup from "./modules/popup"; // Popup dialog
import WallpaperSlider from "./modules/wallpaperSlider"; // Dynamic wallpaper effect
import PartsToGoSlider from "./modules/partstogoSlider"; // Slider on PartsToGo Landing page
import PartsToGoBoxSlider from "./modules/partstogoBoxSlider";
import PartsToGoNavDropdown from "./modules/partstogoNavDropdown"; // Dropdown on PartsToGo Landing page
import SpecialTeaser from "./modules/specialTeaser"; // Special Teaser
import PartsToGoIntro from "./modules/partstogoIntro"; // PartsToGo Intro parallax animation
import PartsToGoAboutFeatures from "./modules/partstogoAboutFeatures"; // PartsToGo About Features parallax animation
import PartsToGoAboutTeam from "./modules/partstogoAboutTeam"; // PartsToGo About Team parallax animation
import UniqueHubspotForms from "./modules/uniqueHubspotForms"; // Unique Hubspot Forms
import PartstogoScrollspy from "./modules/partstogoScrollspy"; // Scrollspy for PartsToGo Landing page
import TeamSlider from "./modules/teamSlider";
import PartstogoOctagonEffect from "./modules/partstogoOctagonEffect"; // Background effect for PartsToGo Octagon
import ProductionJourneySection from "./modules/productionJourneySection";
import PartsJourneySection from "./modules/partsJourneySection";

// Partner modules
import StratasysAccordions from "./modules/stratasysAccordions";
import StratasysVideos from "./modules/stratasysVideos";
import StratasysScrollspy from "./modules/stratasysScrollspy";

const mobileNav = document.getElementById("mobileSwitch");
if (mobileNav) new MobileNav();

// Pin the sidebar in the template-sidebar template
const sidebarTemplate = document.querySelector("main.template-sidebar");
if (sidebarTemplate) new TemplateSidebar(sidebarTemplate);

// const promotionBanner = document.getElementById('promotion-banner');
// if (promotionBanner) new PromotionBanner(promotionBanner);

const cookies = document.getElementById("edit-cookie");
if (cookies) new Cookies(cookies);

const scrollSpy = document.querySelector(".scrollspy");
if (scrollSpy) new ScrollSpy();

const partstogoScrollspy = document.querySelector(".scrollspy--partstogo");
if (partstogoScrollspy) new PartstogoScrollspy(partstogoScrollspy);

const fancybox = document.querySelectorAll(".fancybox");
if (fancybox) new ImageZoom();

const homeIntro = document.getElementById("homeIntro");
if (homeIntro) new HomeIntro(homeIntro);

const productFilter = document.getElementById("productFilter");
if (productFilter) new ProductFilter(productFilter);

const shopFilter = document.getElementById("shopFilter");
if (shopFilter) new ShopFilter(shopFilter);

const manufacturersFilter = document.getElementById("manufacturersFilter");
if (manufacturersFilter) new ManufacturersFilter(manufacturersFilter);

const usecasesFilter = document.getElementById("usecasesFilter");
if (usecasesFilter) new UsecasesFilter(usecasesFilter);

const testimonialSwiper = document.getElementById("testimonials");
if (testimonialSwiper) new TestimonialSwiper(testimonialSwiper);

const technologySwiper = document.getElementById("technologies");
if (technologySwiper) new TechnologySwiper(technologySwiper);

const hexagon = document.querySelector("svg.hexagon");
if (hexagon) new Hexagon();

const slider = document.querySelectorAll(".slider");
if (slider) new Slider(slider);

const shopSlider = document.querySelectorAll(".shop-slider");
if (shopSlider) new ShopSlider(shopSlider);

const anchorButton = document.querySelectorAll(".btn.anchor");
if (anchorButton) new AnchorButton(anchorButton);

const eventCountdown = document.querySelectorAll(".event-card .countdown");
if (eventCountdown) new EventCountdown(eventCountdown);

const minifyHeadline = document.querySelector("main.template-default");
if (minifyHeadline) new Headline(minifyHeadline);

const productTabCardSlider = document.querySelectorAll(
    ".product-tab-card__slider"
);
if (productTabCardSlider) new ProductTabCardSlider(productTabCardSlider);

const accordions = document.querySelectorAll(".accordions");
if (accordions) {
    accordions.forEach((m) => {
        new Accordion(m);
    });
}
const modals = document.querySelectorAll(".modal");
const modalsArray = [...modals];
const uniqueModals = new Set(modalsArray.map((m) => m.id));
if (uniqueModals) {
    uniqueModals.forEach((m) => {
        // new Modal(document.querySelector('#' + m));
        new Modal(document.getElementById(m));
    });
}

const contactForms = document.querySelectorAll("form.contact-form");
if (contactForms) {
    contactForms.forEach((cf) => {
        new ContactForm(cf);
    });
}

// TESTFORM FOR HUBSPOT
// const testForm = document.querySelector('form.test-form');
// if (testForm) {
// 	new TestForm(testForm);
// }

const truncs = document.querySelectorAll(".truncate");
if (truncs) {
    truncs.forEach((t) => {
        new Truncate(t);
    });
}

const eventsList = document.querySelector("body.page-events #passed-events");
if (eventsList) new EventsList();

const anchorTeaser = document.querySelectorAll(".anchor-teaser");
if (anchorTeaser) {
    anchorTeaser.forEach((at) => {
        new AnchorTeaser(at);
    });
}

const downloads = document.querySelectorAll(".download");
if (downloads) {
    downloads.forEach((dl) => {
        new Download(dl);
    });
}

const newsletter = document.getElementById("newsletter");
if (newsletter) new Newsletter(newsletter);

// Popup
const popup = document.getElementById("popup");
if (popup) new Popup(popup);

// Wallpaper Slider
const wallpaperSlider = document.querySelector(".heading__wallpaper");
if (wallpaperSlider) new WallpaperSlider(wallpaperSlider);

// PartsToGo Slider
const partsToGoSlider = document.querySelector(".partstogo-slider");
if (partsToGoSlider) new PartsToGoSlider(partsToGoSlider);

// PartsToGo Box Slider
const partsToGoBoxSlider = document.querySelector(
    ".partstogo-trust__box-slider"
);
if (partsToGoBoxSlider) new PartsToGoBoxSlider(partsToGoBoxSlider);

// PartsToGo Nav Dropdown
const partsToGoNavDropdown = document.querySelectorAll(
    ".partstogo__submenu-container"
);
partsToGoNavDropdown.forEach((p) => {
    new PartsToGoNavDropdown(p);
});

// Special Teaser
const specialTeasers = document.querySelectorAll(".special");
specialTeasers.forEach((st) => {
    new SpecialTeaser(st);
});

const partsToGoIntro = document.getElementById("partstogoIntro");
if (partsToGoIntro) new PartsToGoIntro(partsToGoIntro);

const partsToGoAboutFeatures = document.querySelector(
    ".partstogo-about-intro__features"
);
if (partsToGoAboutFeatures) new PartsToGoAboutFeatures(partsToGoAboutFeatures);

const partsToGoAboutTeam = document.querySelector(
    ".partstogo-about-team__team"
);
if (partsToGoAboutTeam) new PartsToGoAboutTeam(partsToGoAboutTeam);

const teamSlider = document.querySelector(".team-slider__team");
if (teamSlider) new TeamSlider(teamSlider);

// Partstogo Octagon Effect
const partsOctagons = document.querySelectorAll(".partstogo-octagon-effect");
partsOctagons.forEach((st) => {
    new PartstogoOctagonEffect(st);
});

// Custom Stratasys Accordion
const stratasysAccordions = document.querySelectorAll(".stratasys-accordions");
if (stratasysAccordions) {
    stratasysAccordions.forEach((accordions) => {
        new StratasysAccordions(accordions);
    });
}

// Custom Stratasys Videos
const stratasysVideos = document.querySelectorAll(".stratasys-videos__video");
stratasysVideos.forEach((video) => {
    new StratasysVideos(video);
});

// Custom Stratasys Scrollspy
const stratasysScrollspy = document.querySelector(".stratasys-scrollspy");
if (stratasysScrollspy) new StratasysScrollspy(stratasysScrollspy);

document.addEventListener("DOMContentLoaded", function () {
    const uniqueHubspotForms = document.querySelectorAll(".hbspt-form");
    if (uniqueHubspotForms) {
        uniqueHubspotForms.forEach((uhf) => {
            new UniqueHubspotForms(uhf);
        });
    }

    // Production-To-Go Journey Section
    setTimeout(() => {
        const productionJourneySection = document.getElementById("journey");
        if (productionJourneySection)
            new ProductionJourneySection(productionJourneySection);
    }, 600);

    // Production-To-Go Journey Section
    setTimeout(() => {
        const partsJourneySection = document.getElementById("journeyParts");
        if (partsJourneySection) new PartsJourneySection(partsJourneySection);
    }, 600);

    setTimeout(() => {
        // Go through all forms and alert when there is a double
        let forms = document.querySelectorAll(".hbspt-form > form");
        let formIds = [];
        forms.forEach((f) => {
            formIds.push(f.id);
        });
        // Alert when a id is not unique
        let uniqueFormIds = new Set(formIds);
        if (uniqueFormIds.size !== formIds.length) {
            console.error(
                "There are duplicate HubSpot forms on this page. Please make sure that each form has a unique ID.",
                uniqueFormIds,
                formIds
            );
        }
    }, 2000);
});

// Parallax slider
new Ukiyo(".ukiyo");
